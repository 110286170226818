/* .story-post-container-div{
    margin-top: 5vh; 
    width: 450px; 
    height: 550px; 
    margin-left: auto; 
    margin-right: auto; 
    align-items: center; 
    text-align: center;
} */

/* .content-wat{
    max-width: 800px;
    margin: 2rem auto;
    padding: 1rem 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */
.story-post-container-div {
    padding: 16px;
    display: flex;
    justify-content: center;
  }
  
  .content-wat {
    max-width: 800px;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
  }
  
  /* Optional: Style HTML content (paragraphs, headers, etc.) */
  .content-wat p {
    margin-bottom: 16px;
    line-height: 1.6;
  }
h2 {
    color: #106680;
}
h4 {
    font-size: 1.25rem;
    color: #106680;
    margin-top: 1.5rem;
}


.image-from-content{
    width: 410px;
    height: 360px;

    display: block;
    margin: 0 auto; /* Centers the image horizontally */
  

}